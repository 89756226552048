.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}

@primary: #3f6ffb;
@actionColor: #ff5501;
.ant-table-wrapper {
  .ant-pagination {
    padding-right: 12px !important;
  }
}

.ant-layout {
  background-color: #f0f2f6 !important;
}
.ant-table-column-sort {
  background: #fafafa !important;
}